'use client';
import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  IKLink,
} from '@/components/shared/material';
import Image from 'next/image';
import Pagination from '@/components/blog/Pagination';
import AllPostsLoader from './PaginatedPostsLoader';
import PostDate from '@/components/blog/PostDate';
import { Article, Pagination as PaginateType } from '@/types/types';
import {
  getPaginatedArticles,
  getPaginatedArticlesByTag,
  getPaginatedArticlesByAuthor,
} from '@/sanity/services/articleService';
import { getFullUrl } from '@/lib/pathUtils';

export default function PaginatedPosts({
  page,
  fetchType,
  slug,
}: {
  page: number;
  fetchType: 'standard' | 'tagged' | 'author';
  slug?: string;
}) {
  const [currentPage, setPage] = useState(page);
  const [articles, setArticles] = useState<Article[] | null | undefined>([]);
  const [pagination, setPagination] = useState<PaginateType | null | undefined>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const paginatedPostsContainer = useRef(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        let data;
        switch (fetchType) {
          case 'standard':
            data = await getPaginatedArticles(page);
            break;
          case 'tagged':
            if (slug) {
              data = await getPaginatedArticlesByTag(page, slug);
            }
            break;
          case 'author':
            if (slug) {
              data = await getPaginatedArticlesByAuthor(page, slug);
            }
            break;
        }

        setArticles(data?.items);
        setPagination(data?.pagination);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchArticles();
  }, [page, slug, fetchType]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setLoading(true);
  };

  return (
    <section>
      <span className='scroll-mt-40' ref={paginatedPostsContainer}></span>
      {loading ? (
        <AllPostsLoader />
      ) : (
        <div
          className={`container-padding container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3`}
        >
          {articles &&
            articles.length > 0 &&
            articles.map((article) => (
              <IKLink
                className='group flex'
                link={
                  {
                    url: getFullUrl(`blog/${article?.slug}`),
                    type: 'internal',
                  } as any
                }
                key={article?._id}
              >
                <Card className='mt-6 flex w-full transform flex-col border-gray-300 bg-white/80 shadow-[0_0_50px_10px_rgba(0,0,0,0.1)] duration-300 group-hover:translate-y-[-5px]'>
                  <CardHeader className='h-564 relative flex transform items-center bg-gray-900 duration-300 group-hover:translate-y-[-10px]'>
                    <Image
                      src={article?.image?.url ?? '/Logo.svg'}
                      alt={article?.image?.alt ?? article?.title}
                      width={450}
                      height={450}
                      className={`h-full w-full rounded-lg ${
                        article?.image?.url
                          ? 'object-cover ring ring-gray-400'
                          : 'max-h-[80%] object-contain'
                      }`}
                    />
                  </CardHeader>
                  <CardBody className='flex flex-grow flex-col gap-4'>
                    <h5 className='text-xl font-bold text-black'>
                      {article?.title}
                    </h5>
                    <div className='mt-auto flex items-center justify-between'>
                      <span className='text-sm'>
                        By {article?.author?.name}{' '}
                      </span>
                      <span className='text-sm'>
                        <PostDate date={article?.createdAt} />
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </IKLink>
            ))}
        </div>
      )}

      <div className='flex justify-center py-10'>
        {pagination && pagination.totalPageCount > 1 && (
          <Pagination
            loadingFromParent={loading}
            onPageChange={handlePageChange}
            totalPageCount={pagination?.totalPageCount}
            currentPage={currentPage}
            scrollRef={paginatedPostsContainer}
          />
        )}
      </div>
    </section>
  );
}
